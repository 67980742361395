import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContentHero from '../components/ContentHero'
import CardItem from '../components/CardItem'
import { CardItemType } from '../components/CardItem'

const CustomersPage = props => {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  return (
    <Layout>
      <Hero
        title="Erfolgsgeschichten"
        subtitle="Wir sind zufrieden, wenn unsere Kunden zufrieden sind!"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          title="Customer Cases"
          subtitle="Unsere Stärke liegt darin, für unsere Kunden echte Mehrwerte zu generieren. Lesen Sie, welche Nutzen unsere Kunden durch unser konkretes Wirken erzielt haben."
        />
        <section className="section">
          <div className="container">
            <div className="columns is-multiline">
              {edges.map(({ node }) => (
                <div key={node.id} className="column is-one-third">
                  <CardItem
                    image={node.frontmatter.image}
                    title={node.frontmatter.title}
                    description={node.frontmatter.description}
                    slug={node.frontmatter.quote ? null : node.fields.slug}
                    cardItemType={CardItemType.Customer}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

CustomersPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default CustomersPage

export const pageQuery = graphql`
  query CustomerQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "customer-story" }
          preview: { ne: true }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData(height: 256)
              }
            }
            title
            description
            tags
            website
            quote
          }
        }
      }
    }
    hero: file(relativePath: { eq: "customer.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
